<template>
  <li :class="item.tipo">
    <div class="panelContent">
      <p>
        {{ item.name }} 
        <template v-if="item.percentual">({{ item.percentual }})</template>
      </p>
      <h3>{{ item.valor }}</h3>
      <span :class="{ up: item.valor > item.meta, down: item.valor < item.meta }"></span>
    </div>
    <div class="progressbar">
      <span :style="{ width: ( item.percentual > 100 || !item.percentual ? '100%' : item.percentual ) }"></span>
    </div>
  </li>
</template>

<script>
import { formatMoney } from '@/services/utils.service.js'

export default {
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {

    }
  },
  mounted () {
    console.log("item:", this.item)
  },
  methods: {
    formatMoney ( value ) {
      return formatMoney(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  li {
    padding: 0; margin: 0 0 $mg_mini 0;
    border-radius: $border-radius;
    width: 100%; height: 100px;
    list-style: none;
    position: relative; overflow: hidden;
    background-color: $color-secondary;

    p {
      margin: 0; padding: 0;
    }

    .panelContent {
      padding: $hmg_small;

      >span {
        position: absolute;
        top: $hmg_mid; right: $hmg_small;
        
        &.up{
          width: 0; height: 0; 
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 8px solid $color-success-2;
          margin-bottom: 1px;
        }
        &.down {
          width: 0; height: 0; 
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8px solid $color-primary;
          margin-bottom: 1px;
        }
      }
    }

    .progressbar {
      width: 100%; height: 14px;
      position: absolute;
      bottom: 0; left: 0;
      background-color: $color-component;

      >span {
        display: block;
        border-radius: 2em;
        width: 30%; height: 100%;
        background-color: $color-execute;
      }
    }

    &.rede {
      p { color: $color-execute }
      .progressbar {
        span { background-color: $color-execute }
      }
    }
    &.unidade {
      p { color: $color-success }
      .progressbar {
        span { background-color: $color-success }
      }
    }
    &.serie {
      p { color: $color-alert }
      .progressbar {
        span { background-color: $color-alert }
      }
    }
  }
</style>