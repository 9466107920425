<template>
  <section class="atividade-record">
    <h4>Registro e Atendimento</h4>
    <p>Os campos abaixo não são obrigatórios. Utilize-os apenas quando necessário.</p>
    <textarea-control v-model="registro" placeholder="Descreva..." @change="emitRegistro" :disabled="action == 'ver' || disabled"></textarea-control>
    <div>
      <file-control placeholder="Adicionar anexo" @change="addFile" :disabled="action == 'ver' || disabled" :thumb="anexo"></file-control>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String]
    },
    type: {
      type: [Object]
    },
    target: {
      type: String
    },
    action: {
      type: String
    },
    atividade: {
      type: Object
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      registro: null,
      anexo: null
    }
  },
  mounted () {
    this.registro = this.atividade.informacoesAtendimento.registroAtendimento
    this.anexo = this.atividade.informacoesAtendimento.urlDocumento
  },
  methods: {
    addFile ( data ) {
      console.log("data:", data)
      this.anexo = data
      this.$emit("onChange",{ target: 'anexo', data: this.anexo})
    },
    emitRegistro () {
      this.$emit("onChange", { target: 'registroAtendimento', data: this.registro})
    }
  }
}
</script>

<style lang="scss" scoped>
 .atividade-record {
    padding: $hmg_mid;
    border-bottom: $border-component;
    
    h4 {
      margin-bottom: $mg_mini
    }

    > div {
      margin-top: $hmg_mid
    }
  }
</style>