<template>
  <section class="resultSidebar">
    <header>
      <h3>Projeção Faturamento</h3>
      <h5>{{ options.type == 'bruto' ? 'Bruto' : 'Líquido' }}</h5>
    </header>
    <div>
      <div v-if="loading" class="spinner">
        <input-spinner />
      </div>
      <template v-else>
        <div v-if="bc" class="bc">
          <label>Projeção Base Case</label>
          <h3>R${{ bc.totalProjetado }}</h3>
        </div>
        <ul class="panel" v-if="reports">
          <panel v-for="item in reports" :key="item" :item="item" />
        </ul>
      </template>
    </div>
  </section>
</template>

<script>
import Panel from '@/components/planejamentovendas/projecao/utilities/Panel.vue'
import PvService from '@/services/pv.service.js'
import { formatMoney } from '@/services/utils.service.js'
import InputSpinner from '@/components/utilities/InputSpinner.vue'

export default {
  props: {
    options: {
      type: Object
    }
  },
  components: {
    Panel, InputSpinner
  },
  watch: {
    options ( value ) {
      if ( value ) {
        this.setup()
      }
    }
  },
  data () {
    return {
      reports: null,
      bc: null,
      loading: false
    }
  },
  mounted () {
    console.log("options:", this.options)
    if ( this.options ) {
      this.setup()
    }
  },
  methods: {
    setup () {
      if ( this.options.type == 'bruto' ) {
        this.getSidebarBruto()
      }
      if ( this.options.type == 'liquido' ) {
        this.getSidebarLiquido()
      }
    },
    getSidebarBruto () {
      console.log('getSidebarBruto:', this.options)
      this.loading = true
      
      const { unidade, periodoLetivo } = this.options
      const data = {
        unidade,
        periodoLetivo
      }
      // console.log('data:', data)
      PvService.getSidebarBruto( data ).then(
        response => {
          console.log("getSidebarBruto:", response.data)
          this.build(response.data, 'bruto')
        },
        error => {
          console.error(error)
        }
      )
    },
    getSidebarLiquido () {
      this.loading = true

      const { idUnidade, periodoLetivo, idSerie } = this.options
      const data = {
        idUnidade,
        periodoLetivo,
        idSerie
      }

      PvService.getSidebarLiquido( data ).then(
        response => {
          console.log("getSidebarLiquido:", response.data)
          this.bc = response.data.baseCase
          this.build(response.data, 'liquido')
        },
        error => {
          console.error(error)
        }
      )
    },
    build ( data, type ) {
      console.log('type:', type)
      console.log('build:', data)
      
      var sidebar = []
      
      if ( data.periodoLetivo ) {
        sidebar.push({
          name: "Rede",
          tipo: 'rede',
          valor: type == 'bruto' ? formatMoney(data.periodoLetivo.totalProjetado) : 'R$' + data.periodoLetivo.valorLiquidoFinal,
          percentual: type == 'bruto' ? null : data.periodoLetivo.percentual,
        })
      }
      if ( data.unidade ) {
        sidebar.push({
          name: "Unidade",
          tipo: 'unidade',
          percentual: data.unidade.percentual,
          valor: type == 'bruto' ? formatMoney(data.unidade.totalProjetado) : 'R$' + data.unidade.valorLiquidoFinal
        })
      }
      if ( data.serie ) {
        sidebar.push({
          name: "Série",
          tipo: 'serie',
          percentual: data.serie.percentual,
          valor: type == 'bruto' ? formatMoney(data.serie.totalProjetado) : 'R$' + data.serie.valorLiquidoFinal
        })
      }

      this.reports = sidebar
      this.loading = false
    },
    formatMoney ( value ) {
      return formatMoney(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .resultSidebar {
    position: sticky;
    top: 0;
    
    header {
      width: 100%; padding: $hmg_mid;
      border-bottom: $border-component;
      
      h3,h5 {
        margin: 0; padding: 0;
      }
      h3 {
        font-weight: bold;
      }
    }
    
    >div {
      

      ul {
        padding: $hmg_mid;
        margin: 0;
      }
    }
  }

  .bc {
    padding: $hmg_mid;
    border-bottom: $border-component;
    background-color: $color-secondary;
  }

  .spinner {
    text-align: center;
    margin-top: $mg_large;

    >div {
      margin: auto
    }
  }
</style>