<script setup>
  import UserService from '@/services/user.service.js'
  import AcademicoService from '@/services/academico.service.js'
  import FormService from '@/services/form.service.js'
  import { createSelectOptions } from '@/services/utils.service.js'

  import FormSpinner from '@/components/utilities/FormSpinner.vue'
</script>

<template>
  <section class="filterController">
    <form-spinner v-if="loading"/>
    
    <div :class="['frame fixed', { blocked: waiting }]">
      <div class="row no-padd" v-if="type == 'sync'">
        <div class="col-md-5 form-group" v-if="!syncMethod">
          <select-control 
            label="Tipo de Sincronismo" 
            v-model="syncMethod" 
            name="unidade" 
            :options="options.syncMethod"
            placeholder="Selecione" 
            @change="valid.periodoLetivo = true" 
            :disabled="loading"
          />
        </div>
        <div class="gobackWrap" v-else>
          <a href="#" class="btn btn-secondary" @click.prevent="rowBackSync()">
            <fa :icon="['far','arrow-left-long']" />&nbsp;
            Voltar</a>
        </div>
      </div>
      <div class="row no-padd" ref="filterForm" v-if="syncMethod == 'unidade' || type == 'filter'">
        <div class="col-md-5 form-group">
          <select-control 
            label="Unidade" 
            v-model="filter.idUnidade" 
            name="unidade" 
            :options="options.unidades" 
            placeholder="Selecione" 
            @change="getPeriodosLetivos(); valid.unidade = true" 
            :disabled="loading"
            :valid="valid.unidade"
            required
          />
        </div>
        <div class="col-md-3 form-group">
          <select-control 
            label="Tipo de Curso" 
            v-model="filter.idTipoCurso" 
            name="tipoCurso" 
            :options="options.tipoCurso" 
            placeholder="Selecione" 
            :disabled="loading"
            @change="clearFilter('tipoCurso'); selectTipoCurso()"
          />
        </div>
        <div class="col-md-3 form-group">
          <select-control 
            label="Período Letivo" 
            v-model="filter.idPeriodoLetivo" 
            :options="options.periodosLetivos" 
            name="periodoLetivo"
            placeholder="Selecione"
            @change="getSeries(); clearFilter('periodoLetivo'); setPeriodoLetivo(); valid.periodoLetivo = true" 
            :valid="valid.periodoLetivo"
            :disabled="loading"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <select-control
            label="Curso"
            v-model="filter.idCurso"
            :options="options.cursos"
            placeholder="Selecione"
            :disabled="loading"
            @change="clearFilter('curso'); selectCurso();"
          />
        </div>
        <div class="col-md-4 form-group">
          <select-control 
            label="Série" 
            v-model="filter.idSerie" 
            :options="options.series" 
            placeholder="Selecione" 
            :disabled="loading" 
            @change="getTurmas()"
          />
        </div>
        <div class="col-md-4 form-group" v-if="type == 'filter'">
          <select-control 
            label="Turma" 
            v-model="filter.idTurma" 
            :options="options.turmas" 
            placeholder="Selecione" 
            :disabled="loading"
          />
        </div>
      </div>
      <div class="row no-padd" ref="syncFilterForm">
        <div class="col-md-5 form-group" v-if="syncMethod == 'periodo' && type == 'sync'">
          <select-control 
            label="Período Letivo" 
            v-model="filter.periodoLetivo" 
            name="periodoLetivo" 
            :options="options.todosPeriodosLetivos"
            placeholder="Selecione" 
            @change="syncValid.periodoLetivo = true" 
            :disabled="loading"
            :valid="syncValid.periodoLetivo"
            required
          />
        </div>
      </div>
      <footer>
        <a href="#" @click.prevent="runFilter()" class="btn btn-primary" v-if="type == 'filter'">
          Buscar
        </a>
        <a href="#" @click.prevent="runFilter(true)" class="btn btn-primary" :disabled="type =='sync' && !syncMethod" v-else>
          Sincronizar
        </a>
        <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
          Limpar
        </a>
      </footer>
    </div>
  </section>
</template>

<script>

export default {
  component: {
    FormSpinner
  },
  props: {
    type: {
      type: String
    },
    waiting: {
      type: Boolean
    }
  },
  watch: {
    type ( value ) {
      if ( value ) {
        this.getTodosPeriodosLetivos()
      }
    },
  },
  data () {
    return {
      filter: {
        idUnidade: null,
        idPeriodoLetivo: null,
        periodoLetivo: null,
        idTipoCurso: null,
        idCurso: null,
        idSerie: null,
        idTurma: null
      },
      wait: {
        unidades: false,
        tipoCurso: false,
        cursos: false,
        periodosLetivos: false
      },
      options: {
        unidades: [],
        tipoCurso: [],
        periodosLetivos: [],
        todosPeriodosLetivos: [],
        series: [],
        turmas: [],
        cursos: [],
        syncMethod: [
          { label: 'Período Letivo', value: 'periodo' },
          { label: 'Unidade', value: 'unidade' }
        ]
      },
      valid: {
        unidade: true,
        periodoLetivo: true,
      },
      syncValid: {
        periodoLetivo: true
      },
      cursos: [],
      series: [],
      loading: false,
      syncMethod: null
    }
  },
  mounted () {
    this.getUnidades()
    this.getTipoCurso()
    this.getCursos()
    this.getTodosPeriodosLetivos()

    this.loading = true
  },
  methods: {
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          this.options.series = []
          this.options.periodosLetivos = []
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
            this.wait.unidades = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getTipoCurso () {
      AcademicoService.getTipoCurso().then(
        response => {
          console.log("getTipoCurso:", response.data)
          if ( response.data.length > 0 ) {
            this.options.tipoCurso = createSelectOptions(response.data, 'idTipoCurso' ,'descricao')
            this.wait.tipoCurso = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getCursos () {
      AcademicoService.getCursos().then(
        response => {
          console.log("getCursos:", response.data)
          if ( response.data.length > 0 ) {
            this.cursos = response.data
            this.options.cursos = createSelectOptions(response.data, 'idCurso' ,'descricao')
            this.wait.cursos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

         
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.filter.idUnidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    setPeriodoLetivo () {
      console.log("periodosLetivos", this.options.periodosLetivos)

      const periodoLetivo = this.options.periodosLetivos.filter(a => a.idPeriodoLetivo == this.filter.idPeriodoLetivo)
      this.filter.periodoLetivo = periodoLetivo[0].descricao

      console.log("setPeriodoLetivo", periodoLetivo)
    },
    getTodosPeriodosLetivos () {
      AcademicoService.getTodosPeriodosLetivos().then(
        response => {
          if ( response.data.length > 0 ) {
            console.log("getTodosPeriodosLetivos:", response.data)
            this.options.todosPeriodosLetivos = createSelectOptions(response.data, 'descricao', 'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

          this.wait.periodosLetivos = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu! Não foi possível recuperar os períodos letivos necessários", floater: true})
        }
      )
    },
    getSeries () {
      this.loading = true

      AcademicoService.getSeries(this.filter.idPeriodoLetivo, this.filter.idTipoCurso).then(
        response => {
          console.log("getSeries:", response.data)
          this.options.series = []

          if ( response.data.length > 0 ) {
            this.series = response.data
            response.data.map( k => {
              k.label = k.descricao + ' ' + k.descricaoTurno
            })
            this.options.series = createSelectOptions(response.data, 'idSerie' ,'label')
            
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
          }

          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getTurmas () {
      this.loading = true

      const options = {
        idUnidade: this.filter.idUnidade,
        idTipoCurso: this.filter.idTipoCurso,
        idPeriodoLetivo: this.filter.idPeriodoLetivo,
        idCurso: this.filter.idCurso,
        idSerie: this.filter.idSerie
      }
      
      AcademicoService.getTurmasProximoCiclo( options ).then(
        response => {
          console.log("getTurmas:", response.data)
          if ( response.data.length > 0 ) {
            this.options.turmas = createSelectOptions(response.data, 'idTurma', 'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    selectCurso () {
      const selected = this.filter.idCurso
      this.loading = true
      
      const result = this.series.filter( k => {
        return k.idCurso == selected
      })

      if ( result.length > 0 ) {
        this.options.series = createSelectOptions(result, 'idSerie' ,'label')
      } else {
        this.options.series = []
      }
      this.loading = false
    },
    selectTipoCurso () {
      const selected = this.filter.idTipoCurso
      this.loading = true
      
      const result = this.cursos.filter( k => {
        return k.idTipoCurso == selected
      })
      
      if ( result.length > 0 ) {
        this.options.cursos = createSelectOptions(result, 'idCurso' ,'descricao')
      } else {
        this.options.cursos = []
      }
      this.loading = false
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        this.loading = false
      }
      // console.log("finished:", finished)

    },
    clearFilter ( part ) {
      if ( !part ) {
        this.filter = {
          idUnidade: null,
          idPeriodoLetivo: null,
          periodoLetivo: null,
          idTipoCurso: null,
          idCurso: null,
          idSerie: null,
          idTurma: null
        }

        this.$emit('clear')
      } else if ( part === 'tipoCurso' ) {
        this.filter.idCurso = null
        this.filter.idPeriodoLetivo = null
        this.filter.idSerie = null
        this.options.series = this.series
      } else if ( part === 'periodoLetivo' ) {
        this.filter.idCurso = null
        this.filter.idSerie = null
        this.options.series = this.series
      } else if ( part === 'curso' ) {
        this.filter.idSerie = null
        this.options.series = this.series
      }
    },
    runFilter ( sync ) {
      const formTag = sync ? 'syncFilterForm' : 'filterForm'
      const form = this.$refs[formTag]
      const valids = sync ? this.syncValid :  this.valid
      const valid = FormService.validate(form, valids)

      if ( valid.response ) {
        const trigger = sync ? 'onSync' : 'onFilter' 
        this.$emit(trigger, this.filter)
      } else {
        window.toaster.emit('open', {style: "error", message: "Para continuar preencha os campos obrigatórios.", floater: true})
      }
    },
    rowBackSync () {
      this.syncMethod = null
    }
  }
}
</script>

<style scoped lang="scss">
  .filterController {
    border-bottom: $border-component;

    >.frame {
      padding: $hmg_mid;

      &.blocked {
        opacity: .7; pointer-events: none;
      }
    }
  }

  footer {
    display: flex; align-items: center;
    gap: $mg_mini;
    padding: $hmg_small;
  }
  // .frame {
  //   padding: 0 $hmg_small
  // }
  .form-actions {
    margin-top: $hmg !important
  }

  .gobackWrap {
    margin: 0 0 $hmg_mid $mg_mini;
  }
</style>